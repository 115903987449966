import { api } from "config/apiHandler";
import cookiesManipulator from "services/browserStorage/cookies";

const surveys = api.injectEndpoints({
  endpoints: (build) => ({
    getSurvey: build.query({
      query: (surveyToken) => `/surveys?survey_token=${surveyToken}`,

      transformResponse: (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          cookiesManipulator.setCookies(
            "admin_user_image",
            baseQueryReturnValue.data.surveys[0].user.photo_url
          );
          return baseQueryReturnValue.data;
        }
      },
      transformErrorResponse: (baseQueryReturnValue) => baseQueryReturnValue,
    }),

    feedbacks: build.mutation({
      query: (requestBody) => ({
        url: "/feedbacks",
        method: "POST",
        body: requestBody,
      }),
      transformResponse: async (baseQueryReturnValue) => {
        if (baseQueryReturnValue.code !== "success") throw new Error(baseQueryReturnValue.message);
        else {
          return baseQueryReturnValue.data;
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetSurveyQuery, useLazyGetSurveyQuery, useFeedbacksMutation } = surveys;
